<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="1024px">
    <v-card>
      <material-card
        :title="emailSubject"
        tile
        color="green"
        full-width
      >
        <v-container>
          <v-layout wrap>
            <v-flex
              xs12
              md12
            >
              <span v-html="emailContent" />
            </v-flex>
          </v-layout>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
  </v-dialog>
</template>
<script>
import functionUtils from 'utils/functionUtils'
import ToastType from 'enum/toastType'
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      isShowModal: false,
      emailSubject: null,
      emailContent: null
    }
  },
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  methods: {
    /**
     * Check null
     */
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    /**
     * Show modal
     */
    onShowModal: function (emailId, emailSubject) {
      this.emailContent = null
      this.emailSubject = emailSubject
      this.isShowModal = true
      this.GET_EMAIL_HISTORY_CONTENT({ id: emailId }).then(
        function (res) {
          this.emailSubject = res.data.subject
          this.emailContent = res.data.content
        }.bind(this)
      )
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.setDefaultData()
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      if (this.$refs.form.validate()) {
        let filter = {
          name: this.name
        }
        if (!functionUtils.isNull(this.decentralizationGroupId)) {
          this.UPDATE_DECENTRALIZATION_GROUP({ id: this.decentralizationGroupId, ...filter }).then(
            function () {
              this.ON_SHOW_TOAST({
                'message': this.$t('common.success'),
                styleType: ToastType.SUCCESS
              })
              this.setDefaultData()
              this.GET_DECENTRALIZATION_GROUP_LIST()
            }.bind(this)
          )
        } else {
          this.ADD_DECENTRALIZATION_GROUP(filter).then(
            function () {
              this.ON_SHOW_TOAST({
                'message': this.$t('common.success'),
                styleType: ToastType.SUCCESS
              })
              this.setDefaultData()
              this.GET_DECENTRALIZATION_GROUP_LIST()
            }.bind(this)
          )
        }
      }
    },
    /**
     * Set default data
     */
    setDefaultData: function () {
      this.isShowModal = false
      this.emailContent = null
      this.emailSubject = null
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_EMAIL_HISTORY_CONTENT'
    ])
  }
}

</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
