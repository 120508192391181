<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <!-- Config company information -->
    <v-layout
      v-if="!forConasi"
      pt-3
      pl-4>
      <v-flex md12>
        <tabHeader
          ref="tabHeader"
          :tab-items="mainTabItems"
          @onSelectTab="onChangedTab" />
      </v-flex>
    </v-layout>
    <v-layout v-if="mainTabSelected == EmailHistoryTabType.FROM_AGENT">
      <v-flex md12>
        <emailHistoryList />
      </v-flex>
    </v-layout>
    <v-layout v-if="!forConasi && mainTabSelected == EmailHistoryTabType.FROM_GATEWAY">
      <v-flex md12>
        <emailGatewayStatus />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { FOR_CONASI_TARGET } from 'utils/constants'
// import functionUtils from 'utils/functionUtils'
import TabHeader from 'Components/TabHeader'
import EmailGatewayStatus from './EmailGatewayStatus'
import EmailHistoryList from './EmailHistoryList'
const EmailHistoryTabType = {
  FROM_AGENT: 1,
  FROM_GATEWAY: 2
}
export default {
  components: {
    TabHeader,
    EmailGatewayStatus,
    EmailHistoryList
  },
  data: () => ({
    EmailHistoryTabType: EmailHistoryTabType,
    mainTabSelected: EmailHistoryTabType.FROM_AGENT,
    mainTabItems: [
      {
        title: 'emailHistory.smtpAgent',
        id: EmailHistoryTabType.FROM_AGENT
      },
      {
        title: 'emailHistory.mailGateway',
        id: EmailHistoryTabType.FROM_GATEWAY,
        hidden: FOR_CONASI_TARGET
      }
    ],
    forConasi: FOR_CONASI_TARGET
  }),
  computed: {
  },
  watch: {
  },
  created () {
  },
  methods: {
    onChangedTab: function ({ index, item }) {
      if (this.mainTabSelected !== item.id) {
        this.mainTabSelected = item.id
      }
    },
    ...mapActions([
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
