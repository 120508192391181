<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout
      wrap
    >
      <v-flex
        md12
      >
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md6>
                  <v-text-field
                    v-model="emailSearch.keySearch"
                    :label="$t('emailHistory.search')"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSearch"
                @keyup.enter="onSearch"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          :title="$t('emailHistory.gatewayTitle')"
          color="green"
          flat
          full-width
        >
          <div @mouseleave="$refs.tblEmailList.expanded=[]">
            <v-data-table
              ref="tblEmailList"
              :no-data-text="$t('common.noDataAvailable')"
              :headers="emailHistoryHeaders"
              :items="emailList"
              :single-select="true"
              :expand="false"
              hide-actions
            >
              <template
                slot="headerCell"
                slot-scope="{ header }"
              >
                <span
                  class="text--darken-3 font-medium"
                  v-text="$t(header.text)"
                />
              </template>
              <template v-slot:items="props">
                <tr
                  @click="isAdmin() ? props.expanded=!props.expanded : null"
                  @mouseover="isAdmin() ? props.expanded=true : null">
                  <td
                    v-for="(header, index) in emailHistoryHeaders"
                    :key="header.value + index">
                    <span
                      v-if="header.value == 'recipient'"
                      class="a-state primary">{{ props.item[header.value] }}</span>
                    <span
                      v-else-if="header.value == 'sender'"
                      class="a-state default">{{ props.item[header.value] }}</span>
                    <span
                      v-else-if="header.value == 'event'"
                      :class="`a-state ${props.item[header.value]}`">{{ props.item[header.value] }}</span>
                    <span v-else-if="header.value == 'subject'">
                      <div style="min-width: 210px">{{ props.item[header.value] }}</div>
                    </span>
                    <span v-else>{{ props.item[header.value] }}</span>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </material-card>
      </v-flex>
    </v-layout>
    <div class="text-xs-center">
      <v-pagination
        :value="emailPaginate.currentPage"
        :length="emailPaginate.totalPages"
        :total-visible="5"
        @next="onNextPage"
        @previous="onPreviousPage"
      />
    </div>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
    <loading-bar :is-loading="isLoading" />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import functionUtils from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
// import ToastType from 'enum/toastType'
export default {
  components: {
  },
  data: () => ({
    emailHistoryHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt',
        width: '3%',
        align: 'center'
      },
      {
        sortable: false,
        text: 'emailHistory.sender',
        value: 'sender'
      },
      {
        sortable: false,
        text: 'emailHistory.recipient',
        value: 'recipient'
      },
      {
        sortable: false,
        text: 'emailHistory.subject',
        value: 'subject'
      },
      {
        sortable: false,
        text: 'emailHistory.event',
        value: 'event'
      },
      {
        sortable: false,
        text: 'emailHistory.timestamp',
        value: 'timestamp'
      }
    ],
    mailGateway: {
      apikey: null,
      baseUrl: null
    },
    emailList: [],
    emailPaginate: {
      itemPerPage: 25,
      totalPages: 1,
      currentPage: 1,
      firstToken: null,
      lastToken: null,
      nextToken: null,
      previousToken: null
    },
    emailSearch: {
      keySearch: null,
      dateCreate: null
    },
    isLoading: false
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT',
      'GET_MAIL_GATEWAY_EVENTS_DATA'
    ])
  },
  watch: {
    GET_MAIL_GATEWAY_EVENTS_DATA () {
      let res = this.GET_MAIL_GATEWAY_EVENTS_DATA
      let pagination = res.paging
      let results = res.items

      this.emailPaginate.firstToken = pagination.first.split('/').pop()
      this.emailPaginate.lastToken = pagination.last.split('/').pop()
      this.emailPaginate.nextToken = pagination.next.split('/').pop()
      this.emailPaginate.previousToken = pagination.previous.split('/').pop()

      if (results.length < this.emailPaginate.itemPerPage) {
        this.emailPaginate.totalPages = this.emailPaginate.currentPage
      } else {
        this.emailPaginate.totalPages = this.emailPaginate.currentPage + 1
      }

      this.emailList = []
      for (let i = 0, size = results.length; i < size; i++) {
        let item = {
          stt: i + 1 + (this.emailPaginate.currentPage - 1) * this.emailPaginate.itemPerPage,
          sender: results[i].envelope.sender,
          recipient: results[i].recipient,
          subject: results[i].message.headers.subject,
          event: results[i].event,
          timestamp: this.formatDisplayDate(results[i].timestamp)
        }
        this.emailList.push(item)
      }
    }
  },
  created () {
    this.getMailGatewayConfiguration()
  },
  methods: {
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    formatDisplayDate: function (timestamp) {
      var d = new Date(timestamp * 1000)
      return dateUtils.formatBeautyDateTime(d, null)
    },
    onSearch: function () {
      this.getMailGatewayStatus()
    },
    getMailGatewayConfiguration: function () {
      let entityId = sessionStorage.getItem('entityId')
      this.GET_ENTITY_MAIL_GATEWAY_CONFIGURATION({ id: entityId }).then(
        function (res) {
          let data = res.data
          this.mailGateway.apikey = data.mail_gateway_api_key
          this.mailGateway.baseUrl = data.mail_gateway_url
          this.getMailGatewayStatus()
        }.bind(this)
      )
    },
    getMailGatewayStatus: function () {
      let filter = {
        baseUrl: this.mailGateway.baseUrl,
        apiKey: this.mailGateway.apikey,
        params: {
          'limit': this.emailPaginate.itemPerPage,
          // 'recipient': this.emailSearch.keySearch,
          'to': this.emailSearch.keySearch
        }
      }
      this.isLoading = true
      this.emailPaginate.currentPage = 1
      this.GET_MAIL_GATEWAY_EVENTS(filter).then(
        function (res) {
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    getMailGatewayStatusPaging: function (token) {
      let filter = {
        baseUrl: this.mailGateway.baseUrl,
        apiKey: this.mailGateway.apikey,
        pagingToken: token
      }
      this.isLoading = true
      this.GET_MAIL_GATEWAY_EVENTS_PAGING(filter).then(
        function (res) {
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    onNextPage: function () {
      this.emailPaginate.currentPage = this.emailPaginate.currentPage + 1
      this.getMailGatewayStatusPaging(this.emailPaginate.nextToken)
    },
    onPreviousPage: function () {
      if (this.emailPaginate.currentPage > 1) {
        this.emailPaginate.currentPage = this.emailPaginate.currentPage - 1
        this.getMailGatewayStatusPaging(this.emailPaginate.previousToken)
      }
    },
    onPageChange: function (page) {
      this.emailPaginate.currentPage = page
      this.getMailGatewayStatus()
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_ENTITY_MAIL_GATEWAY_CONFIGURATION',
      'GET_MAIL_GATEWAY_EVENTS',
      'GET_MAIL_GATEWAY_EVENTS_PAGING'
    ])
  }
}
</script>
<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer !important;
}
// Hide pagnation current page and more labels
/deep/ .v-pagination__item{
  display: none;
}
/deep/ .v-pagination__more{
  display: none;
}
// Only show selected page label
/deep/ .v-pagination__item--active{
  display: block;
}
</style>
